.font-rouge-script {
  font-family: "Rouge Script", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 45px;
}

.jersey-20-regular {
  font-family: "Jersey 20", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.subfont {
  font-family: "Jersey 25 Charted", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.back{
  /* background-image: url('./image/web.gif'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  /* background-color: black; */
}

/* From Uiverse.io by csemszepp */ 
.container {
  --s: 200px; /* control the size */
  --c1: #1d1d1d;
  --c2: #4e4f51;
  --c3: #3c3c3c;

  background: repeating-conic-gradient(
        from 30deg,
        #0000 0 120deg,
        var(--c3) 0 180deg
      )
      calc(0.5 * var(--s)) calc(0.5 * var(--s) * 0.577),
    repeating-conic-gradient(
      from 30deg,
      var(--c1) 0 60deg,
      var(--c2) 0 120deg,
      var(--c3) 0 180deg
    );
  background-size: var(--s) calc(var(--s) * 0.577);
}
