@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0px;
  padding: 0;
}
body {
  
  box-sizing: border-box;
  background-color: rgb(23, 23, 23);
  
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}